<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
          </template>
          <template v-if="custom_fields.length > 0" v-slot:module_fields>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="assessors_table" :page="page" :parent_module_id="record.id" relation_name="assessors" @edit="editAssessor" @change="load"/>
          <base-table-includable ref="exams_table" :page="page" :parent_module_id="record.id" relation_name="exams" @edit="editExam" @change="load" :parent_module_related_id="record.location_id"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <base-planner-room :loading="loading" :room_name="record.name" :exams="record.exams"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BasePlannerRoom from "../../components/commonComponents/BasePlannerRoom";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BasePlannerRoom,
    BaseTableIncludable,
    BaseTabPanel,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("rooms"),
        name: "rooms",
        model: "room",
      },
      record: {
        location_id: null,
        location_name: null,
        exams: [],
        custom_fields: {}
      },
      usual_fields: [],
      custom_fields: [],
      locations: [],
      tags_required: false,
      loading: false,
    };
  },
  created() {
    this.getLocations();
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
          .then((response) => {
            this.record = response.data;
            if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
              this.record.custom_fields = {};
            }
            if (this.record.exams) {
              this.record.exams = helpFunctions.getLocationsAndRooms(this.record.exams, true, true);
            }
            this.record.location_name = this.record.location_id;
            this.updateIncludableTables();
            this.getFields();
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateIncludableTables() {
      this.$refs.assessors_table.records = this.record.assessors;
      this.$refs.exams_table.records = this.record.exams;
      this.$refs.assessors_table.selected = [];
      this.$refs.exams_table.selected = [];
    },
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.load();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure?")).then((res) => {
            if (res) {
              this.loading = true;
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
                  .then(() => {
                    this.$router.push("/" + this.page.name);
                    this.loading = false;
                    this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
          }
      );
    },
    editAssessor(item) {
      this.$router.push("/assessors/" + item.id);
    },
    editExam(item) {
      this.$router.push("/exams/" + item.id);
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'location_name') {
        this.record.location_id = data[1];
        this.record.location_name = data[1];
      }
      else {
        this.record[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getLocations() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/locations")
          .then((response) => {
            this.loading = false;
            this.locations = [];
            response.data.data.forEach((option) => {
              this.locations.push({
                value: option.id,
                text: option.name,
              });
            });
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.name === 'location_name') {
                  field.available_options = this.locations;
                }
                else if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>